$(document).ready(function () {
  $(".js-filter-link-single-all").click(function () {
    $(".js-filter-link-single").removeClass('c-active');
    $(this).addClass('c-active');
    $('.c-archive-maps-filter .c-box-map').show();
  });

  $('html').on('click', '.js-filter-link-single', function () {
    $(".js-filter-link-single-all").removeClass('c-active');
    if ($(this).hasClass('c-active')) {
      $(".js-filter-link-single").removeClass('c-active');
      $('.c-archive-maps-filter .c-box-map').show();
    } else {
      $(".js-filter-link-single").removeClass('c-active');
      $('.c-archive-maps-filter .c-box-map').show();
      let current = '.' + $(this).data('filter');
      $(this).addClass('c-active');
      $('.c-archive-maps-filter .c-box-map').not(current).hide();
    }
  });

  $('.c-usability').on('click', '.c-usability-font', function () {
    const type = $(this).data('type');

    $('html').removeClass('font-min font-big font-normal');
    $('html').addClass('font-' + type);
    setCookie('font-size', type, 30);
    $('.c-usability-font').removeClass('c-link-active')
    if ($(this).hasClass('c-link-active')) {
      $(this).removeClass('c-link-active');
    } else {
      $(this).addClass('c-link-active');
    }

  });


  $('.c-usability').on('click', '.contrast', function () {

    if ($(this).hasClass('c-link-active')) {
      $(this).removeClass('c-link-active');
      $('html').removeClass('high-contrast');
      setCookie('contrast', 0, 30);
    } else {
      $(this).addClass('c-link-active');
      $('html').addClass('high-contrast');
      setCookie('contrast', 1, 30);

    }

  });


  $("#js-navbar-toggle").click(function () {
    $(".c-header__nav,#js-navbar-toggle,html").toggleClass("active");
  });

  $(".c-read-more__post").click(function () {
    $(".post_content").toggle();
  });

  $(".infografika").click(function () {
    window.location.href = $(this).data("href");
  });



  $( ".c-embed--video" ).append("<div class='c-embed--video-img'><h1>" + $('.c-embed--video iframe').attr("title") + "</h1></div>");

});



$(document).on('keydown', function(e) {
  if(e.keyCode == 37) {
    $('.c-slider .gallery').slick('slickPrev');
  }
  if(e.keyCode == 39) {
    $('.c-slider .gallery').slick('slickNext');
  }
});

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
